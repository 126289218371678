.p-passeditor{
  border: $inputBorder;
  border-radius: 3px;
}

.p-passeditor:hover{
  border-color: $inputHoverBorderColor;
}

.p-passeditor:focus-within{
    @include focused-input();
}

.p-passeditor input.p-inputtext{
  width: 100%;
  border:none;
  outline:none;
}

.p-passeditor input.p-inputtext:focus{
  box-shadow: none;
}