@layer primereact {
    .p-carousel {
        .p-carousel-indicators .p-carousel-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }
    
    .p-galleria {
        .p-galleria-indicators .p-galleria-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }
    
    .p-datatable {
        .p-datatable-tbody {
            > tr {
                &.p-datatable-dragpoint-top > td {
                    box-shadow: inset 0 2px 0 0 $primaryColor;
                }
    
                &.p-datatable-dragpoint-bottom > td {
                    box-shadow: inset 0 -2px 0 0 $primaryColor;
                }
            }
        }
    }
}