$primaryColor: #2196f3 !default;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #e3f2fd !default;
$highlightTextColor: #495057 !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import "../_variables";
